import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import Select from "react-select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { useHttpClient } from "../../utils/http.utils";
import { useEffect, useState } from "react";
import { PersonaCollaborationAppGroupModel, PersonaModel } from "../../models/persona.model";
import { FiSettings } from "react-icons/fi";
import { BsPlus } from "react-icons/bs";
import { Form, Formik } from "formik";

type PersonaSkillsOptions = PersonaCollaborationAppGroupModel & {
    value: string;
    label: string;
    description: string;
    cancel_resource: boolean;
};

interface CancellationRecord {
    resource_id: string;
}

const selectStyles = {
    control: (provided: any) => ({
        ...provided,
        border: "none",
        boxShadow: "none",
        "&:hover": {
            border: "none",
        },
    }),
};

export default function PersonaSkills({ persona, generalAIEntity }: { persona: PersonaModel | undefined, generalAIEntity: PersonaModel | undefined }) {
    const { fetchCollaborationAppGroupForSkillsApi, fetchCancellationResourceApi, createCancellationResourceApi, deleteCancellationResourceApi, attachSkillToPersonaApi } = useHttpClient();
    const [isLoadingList, setIsLoadingList] = useState<boolean[]>([]);
    const [isLoadingResumeList, setIsLoadingResumeList] = useState<boolean[]>([]);
    const [skillsData, setSkillsData] = useState<PersonaSkillsOptions[]>([]);
    const [selectedSkill, setSelectedSkill] = useState<PersonaSkillsOptions>();
    const toast = useToast();
    const [skills, setSkills] = useState<PersonaSkillsOptions[]>([]);
    const skillFormDisc = useDisclosure();
    const [isPersonaCancelled, setIsPersonaCancelled] = useState<boolean>(false);

    useEffect(() => {
        const listPersonaCollaborationAppGroup = async () => {
            let updatedSkillsData,
                cancellationRecords: CancellationRecord[] = [];

            const data = await fetchCollaborationAppGroupForSkillsApi({ status: ["initialized", "active", "cancelled"], isBase: "no" });

            if (!data || !Array.isArray(data)) {
            } else {
                const { personaSkills, skills } = data.reduce(
                    (acc, collaborationAppGroup) => {
                        if (collaborationAppGroup.persona === persona?.id) {
                            acc.personaSkills.push(collaborationAppGroup);
                        } else if (collaborationAppGroup.persona === generalAIEntity?.id) {
                            acc.skills.push(collaborationAppGroup);
                        }
                        return acc;
                    },
                    { personaSkills: [] as PersonaSkillsOptions[], skills: [] as PersonaSkillsOptions[] },
                );

                let dataIds = personaSkills.map((collaborationAppGroup): PersonaSkillsOptions => collaborationAppGroup.id);
                dataIds.push(persona?.id);

                if (dataIds.length > 0) {
                    cancellationRecords = (await fetchCancellationResourceApi({
                        resource_id: dataIds,
                    })) as CancellationRecord[];
                }

                const cancelledResourceIds = new Set(cancellationRecords.map((record) => record.resource_id));

                if (persona?.id && cancelledResourceIds.has(persona.id)) {
                    setIsPersonaCancelled(true);
                }

                updatedSkillsData = personaSkills.map((skill) => ({
                    ...skill,
                    cancel_resource: cancelledResourceIds.has(skill.id),
                }));

                setSkillsData(
                    updatedSkillsData.map(
                        (collaborationAppGroup): PersonaSkillsOptions => ({
                            collaboration_app_group: collaborationAppGroup.id,
                            collaboration_app_group_title: collaborationAppGroup.title,
                            persona: persona?.id!,
                            value: collaborationAppGroup.id,
                            label: collaborationAppGroup.title,
                            description: collaborationAppGroup.description,
                            cancel_resource: collaborationAppGroup.status === "cancelled" || collaborationAppGroup.cancel_resource || false,
                        }),
                    ),
                );

                setSkills(
                    skills.map(
                        (collaborationAppGroup): PersonaSkillsOptions => ({
                            collaboration_app_group: collaborationAppGroup.id,
                            collaboration_app_group_title: collaborationAppGroup.title,
                            persona: persona?.id!,
                            value: collaborationAppGroup.id,
                            label: collaborationAppGroup.title,
                            description: collaborationAppGroup.description,
                            cancel_resource: false,
                        }),
                    ),
                );

                if (persona?.status === "cancelled") {
                    setIsPersonaCancelled(true);
                }

                setIsLoadingList(Array(personaSkills.length).fill(false));
                setIsLoadingResumeList(Array(personaSkills.length).fill(false));
            }
        };

        if (persona?.id && generalAIEntity?.id) {
            listPersonaCollaborationAppGroup();
        } else {
            setSkillsData([]);
            setSkills([]);
        }
    }, [persona, generalAIEntity]);

    const cancelResource = async (index: number, skill: PersonaSkillsOptions) => {
        setIsLoadingList(isLoadingList.map((isLoading, loadingIndex) => (loadingIndex === index ? true : isLoading)));

        try {
            const data = await createCancellationResourceApi(skill?.value, "skill");

            if (data) {
                toast({
                    title: "This resource has been scheduled to be cancelled",
                    status: "success",
                });

                setSkillsData((prevSkillsData) => prevSkillsData.map((skill, skillIndex) => (skillIndex === index ? { ...skill, cancel_resource: true } : skill)));
            }
        } catch (error) {
            toast({
                title: `Error in cancelling the resource`,
                status: "error",
            });
            console.log("Error in createCancellationResourceApi:", error);
        }
        setIsLoadingList(isLoadingList.map((isLoading, loadingIndex) => (loadingIndex === index ? false : isLoading)));
    };

    const resumeResource = async (index: number, skill: PersonaSkillsOptions) => {
        setIsLoadingResumeList(isLoadingResumeList.map((isLoading, loadingIndex) => (loadingIndex === index ? true : isLoading)));
        try {
            const data = await deleteCancellationResourceApi(skill?.value, "skill");

            if (data) {
                toast({
                    title: "The cancelled resource was successfully resumed",
                    status: "success",
                });

                setSkillsData((prevSkillsData) => prevSkillsData.map((skill, skillIndex) => (skillIndex === index ? { ...skill, cancel_resource: false } : skill)));
            }
        } catch (error) {
            toast({
                title: `Error in resuming the resource`,
                status: "error",
            });
            console.log("Error in deleteCancellationResourceApi:", error);
        }
        setIsLoadingResumeList(isLoadingResumeList.map((isLoading, loadingIndex) => (loadingIndex === index ? false : isLoading)));
    };

    const onSubmit = async (_values, { setSubmitting }) => {
        try {
            if (!persona?.id) {
                throw new Error("Persona id is not defined");
            } else if (!selectedSkill?.value) {
                throw new Error("Select a skill to add the persona to");
            } else {
                const data = await attachSkillToPersonaApi(persona.id, selectedSkill.value);

                if (data) {
                    setSkillsData((prevSkillsData) => [
                        ...prevSkillsData,
                        {
                            ...selectedSkill,
                            cancel_resource: false,
                        },
                    ]);

                    // Remove the selected skill from the skills array
                    setSkills((prevSkills) => prevSkills.filter((skill) => skill.value !== selectedSkill.value));

                    toast({
                        title: "The persona has been successfully added to the skill",
                        status: "success",
                    });
                }
            }
        } catch (error) {
            toast({
                title: `Error in adding persona to skill: ${error}`,
                status: "error",
            });
            console.log("Error in createPersonaUserPerceptor:", error);
        }

        setSubmitting(false);
        setSelectedSkill(undefined);
        skillFormDisc.onClose();
    };

    return (
        <>
            <div className="pt-3 pb-8">
                <h1 className="mb-[7px] leading-[1] font-medium text-[16px]">Persona Management</h1>
                <p className="text-slate-500 text-[14px]">
                    Select and add Skills you would like {persona?.first_name} {persona?.last_name} to have. Skills are created in the apply section and they give the ability to your Ai entity to think, handle multiple tools, create chained reasoning cells and more.
                </p>

                <div className="flex justify-between items-center border-b px-4 pb-5 mt-5">
                    <h1>Skills</h1>
                    <div>
                        <Button variant="solid" fontSize="14px" colorScheme="brand" size="sm" onClick={skillFormDisc.onOpen} isDisabled={isPersonaCancelled}>
                            <BsPlus />
                            Add Skill
                        </Button>
                    </div>
                </div>

                <div className="table w-full">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="w-[51px]"></TableHead>
                                <TableHead className="">Name</TableHead>
                                <TableHead className="w-[100px] text-center">Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {skillsData.map((skill, index) => (
                                <TableRow key={skill.collaboration_app_group} className="border-border">
                                    <TableCell className="py-[12px]">
                                        <div className="icon flex items-center justify-center rounded-lg h-[40px] w-[40px] bg-neutral-100 dark:bg-neutral-900">
                                            <FiSettings size="16px" className="h-[18px] w-[18px]" />
                                        </div>
                                    </TableCell>
                                    <TableCell className="py-[12px]">
                                        <p className="w-full m-0 overflow-hidden truncate">{skill.label}</p>
                                        <p className="w-full m-0 text-muted-foreground text-[13px] break-words">{skill.description}</p>
                                    </TableCell>
                                    <TableCell className="text-center w-[100px]">
                                        <div className="flex justify-center gap-[14px]">
                                            <Button variant="solid" fontSize="14px" colorScheme="brand" size="sm" isLoading={isLoadingResumeList[index]} isDisabled={isPersonaCancelled || !skill.cancel_resource} onClick={(e) => resumeResource(index, skill)}>
                                                Resume
                                            </Button>
                                            <Button variant="outline" fontSize="14px" colorScheme="brand" size="sm" isLoading={isLoadingList[index]} isDisabled={skill.cancel_resource} onClick={(e) => cancelResource(index, skill)}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <Modal isOpen={skillFormDisc.isOpen} size={"3xl"} onClose={skillFormDisc.onClose} closeOnOverlayClick={false} motionPreset="slideInBottom">
                <ModalOverlay />
                <ModalContent>
                    <Formik initialValues={{ skills: "" }} onSubmit={onSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <ModalHeader fontWeight={"400"}>Add Skill</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <div className="form-body px-2 border-[1px]">
                                        <Select
                                            placeholder="Select skill to add"
                                            options={skills}
                                            onChange={(selectedOption: any) => {
                                                setSelectedSkill(selectedOption);
                                            }}
                                            className="arin-react-select-container "
                                            classNamePrefix="arin-react-select"
                                            styles={selectStyles}
                                        />
                                    </div>
                                </ModalBody>

                                <ModalFooter>
                                    <Button type="submit" isLoading={isSubmitting} colorScheme="brand" mr={3}>
                                        Save
                                    </Button>
                                    <Button onClick={skillFormDisc.onClose} variant="outline">
                                        Close
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    );
}
