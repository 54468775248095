import { PersonaModel } from "../../models/persona.model";
import { ProfileAvatar } from "../Avatar";
import "../../styles/personas/PersonaListCard.component.scss";
import { useEffect, useState } from "react";
import { GiDeadHead, GiNightSleep } from "react-icons/gi";
import { getPersonaProfilePicture } from "../../utils/personas.utils";
import { capitalizeWord } from "../../utils/strings.utils";
import { Badge, Stack } from "@chakra-ui/react";

interface PersonaListCardProps {
    data: PersonaModel;
    isActive?: boolean;
    state?: "sleep" | "active" | "dead";
    onClick?: (persona: PersonaModel) => void;
}

export default function PersonaListCard(props: PersonaListCardProps) {
    const [personaStateClass, setPersonaStateClass] = useState<string>("");
    const [profilePicture, setProfilePicture] = useState<string>("");

    useEffect(() => {
        // create class depending on state
        switch (props.state) {
            case "sleep":
                // setPersonaStateClass("bg-gray-50 text-yellow-900");
                break;
            case "active":
            default:
                // setPersonaStateClass("");
                break;
            case "dead":
                // setPersonaStateClass("border-gray-400 bg-gray-50 text-gray-900");
                break;
        }

        if (props.data.profile_photo) {
            setProfilePicture(props.data.profile_photo);
        } else {
            const url = getPersonaProfilePicture(props.data);
            setProfilePicture(url);
        }
    }, [props.state]);

    const handleClick = (data: PersonaModel) => {
        if (props.onClick) {
            props.onClick(props.data);
        }
    };

    return (
        <div className={`persona-list-card hover:bg-neutral-100 rounded-lg ${props.isActive && props.state !== "dead" ? "bg-blue-200/50" : ""} px-3 py-2`} onClick={() => handleClick(props.data)}>
            <div className="grid grid-cols-[40px_auto] gap-x-3">
                <div className="profile-section flex-shrink-0 pt-[4px] w-[40px]">
                    <ProfileAvatar type="persona" src={profilePicture} size="40px" />
                </div>
                <div className="w-full overflow-hidden pt-0.5">
                    <h4 className="text-sm font-medium truncate overflow-x-hidden w-full">{props.data.first_name + " " + props.data.last_name}</h4>
                    {/* <p className="type text-[12px] text-zinc-400">{capitalizeWord(props.data.type || "")}</p> */}

                    <Stack direction="row" className="mt-0.5 justify-between" gap={"4px"}>
                        <span className="inline-block text-[11px] text-neutral-800 px-2 rounded-full border border-neutral-400">{capitalizeWord(props.data.type || "")}</span>
                        <PersonaStatusBadge status={props.data.status} />
                    </Stack>
                </div>
                {/* <div className="actions pt-[4px]">
                    {props.state === "sleep" && <GiNightSleep className="text-yellow-600" size="18px" />}
                    {props.state === "dead" && <GiDeadHead className="text-gray-800" size="18px" />}
                </div> */}
            </div>
        </div>
    );
}

const PersonaStatusBadge = ({ status }: { status?: PersonaModel["status"] }) => {
    switch (status) {
        case "draft":
        default:
            return <span className="inline-block text-[11px] text-gray-800 bg-blue-50 px-2 rounded-full">{capitalizeWord(status || "")}</span>;
        case "active":
            return <span className="inline-block text-[11px] text-green-800 bg-green-50 px-2 rounded-full">Active</span>;
        case "deleted":
            return <span className="inline-block text-[11px] text-red-800 bg-red-50 px-2 rounded-full">Removed</span>;
        case "cancelled":
            return <span className="inline-block text-[11px] text-orange-800 bg-orange-50 px-2 rounded-full">Cancelled</span>;
        case "inactive":
            return <span className="inline-block text-[11px] text-yellow-800 bg-yellow-50 px-2 rounded-full">Inactive</span>;
    }
};
