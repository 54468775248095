import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganizationMemberModel, OrganizationModel, VendorProfile } from "../../models/organization.model";
import { SubscriptionModel } from "../../models/stripe.model";
import { UserModel } from "../../models/user.model";
export interface AccountApiKeys {
    openAi: {
        apiKey: string;
    };
    pinecone: {
        key: string;
        env: string;
        index: string;
    };
}

declare type SubscriptionStatus = "not_fetching" | "loading" | "fetched_error" | "fetched";

export interface OrganizationState extends OrganizationModel {
    members?: OrganizationMemberModel[];
}

export interface AccountState {
    isAccountLoading: boolean;
    apiKeys: AccountApiKeys;
    accountData: UserModel | null;
    accountExists: boolean;
    activeOrganizationId: string;
    vendorProfile?: VendorProfile | undefined;
    organizations: OrganizationState[];
    activeOrganization: OrganizationState | null;
    subscription: SubscriptionModel | null;
    subscriptionStatus: SubscriptionStatus;
}

const initialState: AccountState = {
    isAccountLoading: true,
    accountExists: false,
    accountData: null,
    activeOrganizationId: window.sessionStorage.getItem("active_org") || "",
    activeOrganization: null,
    organizations: [],
    apiKeys: {
        openAi: {
            apiKey: "",
        },
        pinecone: {
            env: "",
            key: "",
            index: "",
        },
    },
    subscription: null,
    subscriptionStatus: "not_fetching",
};

const accountSlice = createSlice({
    name: "account-slice",
    initialState,
    reducers: {
        setUserAccountLoading(state, action: PayloadAction<boolean>) {
            state.isAccountLoading = action.payload;
        },
        setUserAccountExists(state, action: PayloadAction<boolean>) {
            state.accountExists = action.payload;
        },
        setAccountApiKeys(state, actions: PayloadAction<AccountApiKeys>) {
            state.apiKeys = actions.payload;
        },
        setAccountData(state, actions: PayloadAction<UserModel>) {
            state.accountData = actions.payload;
        },
        setAccountOrganizations(state, actions: PayloadAction<OrganizationState[]>) {
            state.organizations = actions.payload.map((organization) => {
                organization.members = [];
                return organization;
            });
        },
        setAccountSubscriptionData(state, actions: PayloadAction<SubscriptionModel>) {
            state.subscription = actions.payload;
        },
        clearSubscriptionData(state) {
            state.subscription = null;
            if (state.accountData) {
                state.accountData.backend_subscription_status = "inactive";
            }
        },
        setSubscriptionStatus(state, actions: PayloadAction<SubscriptionStatus>) {
            state.subscriptionStatus = actions.payload;
        },
        setOrganizations(state, actions: PayloadAction<OrganizationState[]>) {
            state.organizations = actions.payload;
        },
        setActiveOrganization(state, actions: PayloadAction<OrganizationState>) {
            state.activeOrganization = actions.payload;
        },
        setUserRole(state, actions: PayloadAction<"admin" | "dbs" | "persona_user">) {
            if (state.accountData) {
                state.accountData.role = actions.payload;
            }
        },
        setActiveOrganizationId(state, actions: PayloadAction<string>) {
            state.activeOrganizationId = actions.payload;
        },
        setVendorProfile(state, actions: PayloadAction<VendorProfile>) {
            state.vendorProfile = actions.payload;
        },
    },
});
export const { setAccountApiKeys, setUserAccountExists, setUserAccountLoading, setAccountData, setAccountOrganizations, setAccountSubscriptionData, clearSubscriptionData, setSubscriptionStatus, setOrganizations, setActiveOrganization, setUserRole, setActiveOrganizationId, setVendorProfile } = accountSlice.actions;
export default accountSlice.reducer;
