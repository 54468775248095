import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

/**
 * This function merges the classes and returns the final class
 * @param inputs classes
 * @returns string
 */
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}
