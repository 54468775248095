import { Alert, AlertIcon, Button, FormControl, FormErrorMessage, FormHelperText, Input, useToast } from "@chakra-ui/react";
import { Field, Formik, FormikHelpers, Form, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useHttpClient } from "../../utils/http.utils";

const PerceptorSetup: React.FC = () => {
    const { createMailGunPerceptorApi, updateMailGunPerceptorApi, listUserPerceptor, createTwilioPerceptorApi, updateTwilioPerceptorApi } = useHttpClient();
    const [userPerceptor, setUserPerceptor] = useState<any[]>([]);
    const formikRef = useRef<FormikProps<any>>(null);
    // const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [currentMailGun, setCurrentMailGun] = useState<string>("");
    const [currentUserSid, setCurrentUserSid] = useState<string>("");
    const [currentAuthToken, setCurrentAuthToken] = useState<string>("");

    const toast = useToast();

    useEffect(() => {
        const fetchUserPerceptor = async () => {
            const data = await listUserPerceptor();

            if (data) {
                setUserPerceptor(data);

                if (formikRef.current) {
                    const mailGunPerceptor = data.find((record) => record.perceptor_code === "mailgun");

                    console.log("mailGunPerceptor", mailGunPerceptor);
                    
                    if (mailGunPerceptor) {
                        formikRef.current.setFieldValue("mailGunApiKey", mailGunPerceptor?.perceptor_setting?.api_key, false);
                        setCurrentMailGun(mailGunPerceptor?.perceptor_setting?.api_key);
                    }

                    const twilioPerceptor = data.find((record) => record.perceptor_code === "twilio");
                    if (twilioPerceptor) {
                        formikRef.current.setFieldValue("twilioAccountSid", twilioPerceptor?.perceptor_setting?.account_sid, false);
                        formikRef.current.setFieldValue("twilioAuthToken", twilioPerceptor?.perceptor_setting?.auth_token, false);
                        setCurrentAuthToken(twilioPerceptor?.perceptor_setting?.auth_token);
                        setCurrentUserSid(twilioPerceptor?.perceptor_setting?.account_sid);
                    }
                }
            }
        };

        fetchUserPerceptor();
    }, []);

    // const requestAccess = async () => {
    //     setIsWhitelisted(true);
    // }

    const handleFormSubmit = async (values: any, actions: FormikHelpers<any>) => {
        // if mailgun api key is configured...
        if (values.mailGunApiKey) {
            if (values.mailGunApiKey !== currentMailGun) {
                const mailGunPerceptor = userPerceptor.find((record) => record.perceptor_code === "mailgun");

                if (mailGunPerceptor) {
                    await updateMailGunPerceptorApi(values.mailGunApiKey);
                    setCurrentMailGun(values.mailGunApiKey);

                    toast({
                        title: "Mail Gun User Perceptor has been updated successfully",
                        status: "success",
                    });
                } else {
                    await createMailGunPerceptorApi(values.mailGunApiKey);
                    setCurrentMailGun(values.mailGunApiKey);

                    toast({
                        title: "Mail Gun User Perceptor has been created successfully",
                        status: "success",
                    });
                }
            }
        }

        // if twilio account sid and auth token are configured...
        if (values.twilioAccountSid && values.twilioAuthToken) {
            if (values.twilioAccountSid !== currentUserSid || values.twilioAuthToken !== currentAuthToken) {
                const twilioPerceptor = userPerceptor.find((record) => record.perceptor_code === "twilio");

                if (twilioPerceptor) {
                    await updateTwilioPerceptorApi(values.twilioAccountSid, values.twilioAuthToken);

                    setCurrentAuthToken(values.twilioAuthToken);
                    setCurrentUserSid(values.twilioAccountSid);

                    toast({
                        title: "Twilio User Perceptor has been updated successfully",
                        status: "success",
                    });
                } else {
                    await createTwilioPerceptorApi(values.twilioAccountSid, values.twilioAuthToken);

                    setCurrentAuthToken(values.twilioAuthToken);
                    setCurrentUserSid(values.twilioAccountSid);

                    toast({
                        title: "Twilio User Perceptor has been created successfully",
                        status: "success",
                    });
                }
            }
        } else {
            if (values.twilioAccountSid && !values.twilioAuthToken) {
                formikRef.current?.setErrors({
                    twilioAuthToken: "The Twilio Auth Token is required with a Twilio Account SID",
                });
            }

            if (values.twilioAuthToken && !values.twilioAccountSid) {
                formikRef.current?.setErrors({
                    twilioAccountSid: "The Twilio Account SID is required with a Twilio Auth Token",
                });
            }
        }

        // remove loader
        actions.setSubmitting(false);
    };

    return (
        <div className="accounts-subview accounts-members h-full">
            <Formik innerRef={formikRef} initialValues={{ mailGunApiKey: "", twilioAccountSid: "", twilioAuthToken: "" }} onSubmit={handleFormSubmit}>
                {({ isSubmitting }) => (
                    <Form className="h-full overflow-hidden">
                        <div className="side-nav-sm with-action">
                            <div className="">
                                <h4 className="title">Perceptors</h4>
                                <p className="subtitle">Configure your account perceptors.</p>
                            </div>
                            <div className="actions">
                                <Button size="sm" colorScheme="brand" type="submit" isLoading={isSubmitting} loadingText="Saving..">
                                    save
                                </Button>
                            </div>
                        </div>

                        <div className="body px-6 pt-4">
                            <Alert status="info" borderRadius={"8px"} width={"700px"}>
                                <AlertIcon />
                                Please note that you can only set up one account for each of the following.
                            </Alert>

                            <div className="form pt-4 w-full md:w-3/4">
                                <div className="section">
                                    <h4 className="title text-[15px] font-semibold">Mailgun</h4>
                                    <p className="text-neutral-500 text-[13px] mb-3">Mailgun is an email delivery service used for sending, receiving in your perceptors.</p>
                                    <p className="text-[14px] font-normal mb-2">API Key</p>
                                    <Field name="mailGunApiKey">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.mailGunApiKey && form.touched.mailGunApiKey}>
                                                <Input {...field} placeholder="API Key" maxWidth={"400px"} size={"sm"} />
                                                <FormErrorMessage fontSize={"12px"}>{form.errors.mailGunApiKey}</FormErrorMessage>
                                                <FormHelperText fontSize={"13px"} className="mb-5">
                                                    This key is essential for authenticating your application with Mailgun.
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Field>
                                </div>
                            </div>

                            {/* {!isWhitelisted &&
                                <Alert status="warning" borderRadius={"8px"} display="flex" justifyContent="space-between" alignItems="center" width={"700px"}>
                                    <div style={{ display: "flex" }}>
                                        <AlertIcon />
                                        You do not have access to the following service.
                                    </div>
                                    <Button
                                        size="sm"
                                        colorScheme="brand"
                                        onClick={requestAccess}
                                    >
                                        Request Access
                                    </Button>
                                </Alert>
                            } */}

                            <div className="form pt-4 w-full md:w-3/4">
                                <div className="section">
                                    <h4 className="title text-[15px] font-semibold">Twilio</h4>
                                    <p className="text-neutral-500 text-[13px] mb-3">Twilio is a cloud communications platform used for making and receiving calls and messages in your perceptors.</p>

                                    <p className="text-[14px] font-normal mb-2">Account SID</p>
                                    <Field name="twilioAccountSid">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.twilioAccountSid && form.touched.twilioAccountSid}>
                                                <Input {...field} placeholder="Account SID" maxWidth={"400px"} size={"sm"} />
                                                <FormErrorMessage fontSize={"12px"}>{form.errors.twilioAccountSid}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <p className="text-[14px] font-normal mb-2 mt-3">Auth Token</p>
                                    <Field name="twilioAuthToken">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.twilioAuthToken && form.touched.twilioAuthToken}>
                                                <Input {...field} placeholder="Auth Token" maxWidth={"400px"} size={"sm"} />
                                                <FormErrorMessage fontSize={"12px"}>{form.errors.twilioAuthToken}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PerceptorSetup;
