import { useEffect, useState } from "react";
import "../styles/ArinLayout.component.scss";
import { FiChevronDown, FiSettings } from "react-icons/fi";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
// import { getUserRoleDescription } from "../utils/auth.utils";
import { UserModel } from "../models/user.model";
import { useLocation, useNavigate } from "react-router-dom";
import { CgArrowsExchange } from "react-icons/cg";
import { ProfileAvatar } from "./Avatar";
import { FaBuilding } from "react-icons/fa";
import SwitchOrganizationsModal from "./accounts/SwitchOrganizationsModal";
import { AiOutlineLogout } from "react-icons/ai";
import { useSelector } from "react-redux";
import { AccountState } from "../store/slices/account.slice";
import { getUserName } from "../utils/user.utils";
import KeycloakClient from "../providers/auth/keycloak-client";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import NeoWorlderIcon from "./layout/neo-worlder-icon";
import { IoChevronForwardSharp } from "react-icons/io5";
import { capitalizeWord } from "../utils/strings.utils";

interface ArinLayoutProps {
    children?: any;
}

export default function ArinLayout(props: ArinLayoutProps) {
    const navigate = useNavigate();
    const [showOrganizationModal, setShowOrganizationModal] = useState(false);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("");
    const [breadcrumb, setBreadcrumb] = useState<string[]>([]);

    useEffect(() => {
        const [path] = location.pathname.split("/").filter((p) => !!p);
        setActiveTab(path);
        setBreadcrumb([path]);
    }, [location]);

    const userLogout = () => {
        KeycloakClient.getInstance().logout();
    };

    function navigateToUUI(): void {
        const activeOrg = accountState.activeOrganizationId;
        window.open(`${process.env.REACT_APP_NEO_UI_URL!}/initializer?vendor=${activeOrg}`, "_blank");
    }

    // const showChangeOrganizationModal = () => {
    //     setShowOrganizationModal(true);
    // };

    return (
        <>
            <nav className="arin-navbar relative">
                <div className="navbar-leading">
                    <a href="/">
                        <img src="https://assets.neoworlder.com/img/v1/lifelab_logo_black.png" alt="" className="arin-logo" />
                    </a>

                    {/* <ul className="nav-bar-links"> */}
                    {/* <li>
                            <NavLink
                                to="/ask-anything"
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                            >
                                Find
                            </NavLink>
                        </li> */}
                    {/* <li>
                            <Menu>
                                <MenuButton>
                                    <div className="flex gap-1.5 items-center">
                                        <p className="profile-name text-slate-500">
                                            Data
                                        </p>
                                        <FiChevronDown className="icon" />
                                    </div>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem
                                        onClick={() => navigate("/folders")}
                                    >
                                        My Folders
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => navigate("/refinements")}
                                    >
                                        Refinements
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            navigate("/store-memories")
                                        }
                                    >
                                        Memories
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </li> */}
                    {/* <li>
                            <NavLink to="/personas" className={({ isActive }) => (isActive ? "active" : "")}>
                                Personas
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/skills/applications/ideation" className={({ isActive }) => (isActive ? "active" : "")}>
                                Skills
                            </NavLink> */}
                    {/*<Menu>*/}
                    {/*	<MenuButton>*/}
                    {/*		<div className="flex gap-1.5 items-center">*/}
                    {/*			<p className="profile-name text-slate-500">*/}
                    {/*				Apply*/}
                    {/*			</p>*/}
                    {/*			<FiChevronDown className="icon" />*/}
                    {/*		</div>*/}
                    {/*	</MenuButton>*/}
                    {/*	<MenuList>*/}
                    {/*		<MenuItem*/}
                    {/*			onClick={() =>*/}
                    {/*				navigate("/skills/applications/invitees")*/}
                    {/*			}*/}
                    {/*		>*/}
                    {/*			Collaborations*/}
                    {/*		</MenuItem>*/}
                    {/*		<MenuItem*/}
                    {/*			onClick={() =>*/}
                    {/*				navigate("/skills/prompt-engineering")*/}
                    {/*			}*/}
                    {/*		>*/}
                    {/*			Prompt Engineering*/}
                    {/*		</MenuItem>*/}
                    {/*	</MenuList>*/}
                    {/*</Menu>*/}
                    {/* </li> */}
                    {/* <li>*/}
                    {/*  <NavLink*/}
                    {/*      to="/account"*/}
                    {/*      className={({ isActive }) =>*/}
                    {/*          isActive ? "active" : ""*/}
                    {/*      }*/}
                    {/*  >*/}
                    {/*    account*/}
                    {/*  </NavLink>*/}
                    {/*</li> */}
                    {/* </ul> */}
                </div>
                <div className="absolute left-[345px]">
                    <div className="flex items-center gap-x-1.5">
                        <NeoWorlderIcon size={20} />
                        <span>
                            <IoChevronForwardSharp size={"15px"} />
                        </span>
                        <p className="text-sm text-neutral-500">Life Lab</p>
                        <span>
                            <IoChevronForwardSharp size={"15px"} />
                        </span>
                        {breadcrumb.map((b, i) => (
                            <div key={`path-${i}`} className="flex gap-x-1.5 items-center">
                                {i > 0 && (
                                    <span>
                                        <IoChevronForwardSharp size={"15px"} />
                                    </span>
                                )}
                                <p className="text-sm text-neutral-500">{capitalizeWord(b)}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <Tabs value={activeTab} className="w-[200px]">
                        <TabsList className="grid w-full grid-cols-2">
                            <TabsTrigger onClick={() => navigate("/personas")} value="personas">
                                Personas
                            </TabsTrigger>
                            <TabsTrigger onClick={() => navigate("/skills/applications/ideation")} value="skills">
                                Skills
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                </div>
                <div className="navbar-actions">
                    <Menu>
                        <MenuButton>
                            <div className="profile-info">
                                <ProfileAvatar src={accountState.accountData?.profile_picture} size="36px" />
                                <div className="profile-description flex flex-col items-baseline gap-[1px]">
                                    <div className="flex gap-[3px] items-center">
                                        <p className="profile-name">{getUserName(accountState?.accountData as UserModel)}</p>
                                        <FiChevronDown />
                                    </div>
                                    <p className="team-name">{accountState.vendorProfile?.name}</p>
                                </div>
                            </div>
                        </MenuButton>
                        <MenuList>
                            <MenuItem className="border-b">
                                <div className="">
                                    <div className="mb-1 flex gap-[2px] items-center">
                                        <div className="icon">
                                            <div className="h-[25px] w-[25px] bg-gray-400 rounded-[4px] flex justify-center items-center">{accountState.vendorProfile?.profilePicture ? <img src={accountState.vendorProfile?.profilePicture} alt="" className="h-[25px] w-[25px] rounded" /> : <FaBuilding size="15px" className="text-white" />}</div>
                                        </div>
                                        &nbsp;{accountState.vendorProfile?.name || "No Organization"}
                                    </div>
                                    {/* {accountState.organizations.length > 1 && (
                                        <span className="font-medium text-blue-500 text-[13px] cursor-pointer pt-1 pb-2 block pl-[32px]" onClick={showChangeOrganizationModal}>
                                            Change Organization
                                        </span>
                                    )} */}
                                </div>
                            </MenuItem>
                            <MenuItem onClick={() => navigateToUUI()}>
                                <CgArrowsExchange className="text-xl" />
                                &nbsp;Interactive Canvas
                            </MenuItem>
                            {/*<MenuItem onClick={() => navigate("/profile")}>*/}
                            {/*	Profile*/}
                            {/*</MenuItem>*/}
                            <MenuItem onClick={() => navigate("/account")}>
                                <FiSettings size="16px" />
                                &nbsp;Settings
                            </MenuItem>
                            <MenuItem onClick={userLogout} className="text-red-400">
                                <AiOutlineLogout size="16px" className="text-red-500" />
                                &nbsp; <span className="text-red-500">Log out</span>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </nav>
            <div className={`arin-body`}>{props.children}</div>
            {/* Modals */}
            <SwitchOrganizationsModal isOpen={showOrganizationModal} onClose={() => setShowOrganizationModal(false)} />
        </>
    );
}
