import { act, useEffect, useState } from "react";
import { useHttpClient } from "../utils/http.utils";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState, setAuthState, setAuthUser } from "../store/slices/auth.slice";
import KeycloakClient from "../providers/auth/keycloak-client";
import { UserProfile } from "../models/user.model";
import { useLocation, useSearchParams } from "react-router-dom";
import { PersonaState } from "../store/slices/persona.slice";
import hotToast from "react-hot-toast";
import { AccountState } from "../store/slices/account.slice";
import { ROUTES } from "../routes";
// import { useGenericModal } from "../components/modals/GenericModalContext";
import { AutoInteractionMessage } from "../models/collaboration.model";
import { addAutoInteractionMessage, addBrainActivityMessage, CollaborateState, setIsCollaborateLoading, setSocketConnected } from "../store/slices/collaborate.slice";
import { socket } from "../utils/socket.utils";

interface KeycloakWrapperProps {
    children: React.ReactNode;
}

const KeycloakWrapper: React.FC<KeycloakWrapperProps> = ({ children }) => {
    const { fetchUserCredentialsApi, fetchPersonaApi, getVendorPublicDataApi } = useHttpClient();
    // const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const authenticated = useSelector(selectAuthState);
    // const [organizationId, setOrganizationId] = useState<string>(sessionStorage.getItem("active_org") || "");
    const personaState: PersonaState = useSelector((state) => (state as any).persona);
    // const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const collaborateState: CollaborateState = useSelector((state) => (state as any).collaborate);
    // const location = useLocation();
    // const { openPaywallModal, closePaywallModal } = useGenericModal();

    useEffect(() => {
        (async () => {
            KeycloakClient.getInstance().addSuccessCallback(() => {
                dispatch(setAuthState(true));
            });

            // Add getAuthToken function to the window object so the prompt engineering iframe can call
            // it to get the auth token
            (window as any).getKeycloakAuthUserToken = () => KeycloakClient.getInstance().getAuthUserToken();

            await KeycloakClient.getInstance().init();
        })();
    }, [dispatch]);

    // useEffect(() => {
    //     if (!organizationId && searchParams.get("org")) {
    //         setOrganizationId(searchParams.get("org")!);
    //         localStorage.setItem("active_org", searchParams.get("org")!);
    //     }
    // }, []);

    useEffect(() => {
        if (authenticated && !!accountState.activeOrganizationId) {
            (async () => {
                const authUser = await KeycloakClient.getInstance().getAuthProfile();

                await fetchUserCredentialsApi(authUser?.email);

                const authUserData: UserProfile = {
                    email: authUser?.email || "",
                    authUserId: authUser?.id || "",
                    isActive: true,
                };

                if (authUserData.authUserId) {
                    dispatch(setAuthState(true));
                    dispatch(setAuthUser(authUserData));
                }

                if (personaState.personas.length === 0) {
                    fetchPersonaApi(null, null, "set", true).catch(console.error);
                }

                // if (paymentMethodState.data.length === 0) {
                //     listPaymentMethodApi().catch((err) => {
                //         hotToast.error("Cannot fetch your payment methods at this time, Please try again later");
                //     });
                // }
                // load organizations
                if (!accountState.vendorProfile) {
                    getVendorPublicDataApi(accountState.activeOrganizationId as string);
                    // loadSetOrganizationAndMembersApi(accountState.accountData.id, organizationId as string).then(({ organizations, activeOrganization }) => {
                    //     // Check if the react router path is equal to /find-anything
                    //     const restrictedPaths = [ROUTES.APPLY, ROUTES.PERSONAS];
                    //     const hasAccess = accountState.accountData?.backend_subscription_status === "active" || activeOrganization?.user?.backend_subscription_status === "active";
                    //     if (restrictedPaths.some((path) => location.pathname.startsWith(path))) {
                    //         // check for the user's subscription
                    //         if (!hasAccess) {
                    //             openPaywallModal();
                    //         } else {
                    //             closePaywallModal();
                    //         }
                    //     } else {
                    //         closePaywallModal();
                    //     }
                    // });
                }
                // load user subscriptions
                // if (!accountState.subscription && accountState.accountData?.stripe_customer_id) {
                //     // TODO: LOAD SUBSCRIPTIONS
                //     listUserSubscriptionApi();
                // }
            })();
        }
    }, [authenticated, dispatch, accountState.activeOrganizationId]);

    // useEffect(() => {
    //     /**
    //      *  This block checks the subscription status of the user, this is runs whenever the user navigates to a new page
    //      *  This is to make sure that the user is not able to access the restricted pages
    //      *
    //      */
    //     if (accountState.accountData) {
    //         // Check if the react router path is equal to /find-anything
    //         const restrictedPaths = [ROUTES.APPLY, ROUTES.PERSONAS];
    //         const hasAccess = accountState.accountData?.backend_subscription_status === "active" || accountState.activeOrganization?.user?.backend_subscription_status === "active";

    //         if (restrictedPaths.some((path) => location.pathname.startsWith(path))) {
    //             // check for the user's subscription
    //             if (!hasAccess) {
    //                 openPaywallModal();
    //             } else {
    //                 closePaywallModal();
    //             }
    //         } else {
    //             closePaywallModal();
    //         }
    //     }
    // }, [location, accountState.accountData]);

    const isBrainActivityMessage = (data: AutoInteractionMessage) => !!data.thought || !!data.action || !!data.observation || !!data.content_log || !!data.evaluation || !!data.tool_result;

    function hideLoaderAfterExecution(data: AutoInteractionMessage) {
        if (data.message_type === "ai") {
            if (!isBrainActivityMessage(data)) {
                dispatch(setIsCollaborateLoading(false));
            } else {
                dispatch(setIsCollaborateLoading(true));
            }
        }
    }

    /**
     * Socket listener hook, this hook runs every time the collaborate state changes
     */
    useEffect(() => {
        if (collaborateState.isInitialized && (collaborateState.socketRoom || collaborateState.selectedCollaborationApp?.id)) {
            // connect to sockets
            socket.connect();
        }

        function onConnect() {
            console.log("connected to the socket"); //debug

            dispatch(setSocketConnected(true));
        }

        function onDisconnect() {
            console.log("disconnected to the socket"); //debug

            dispatch(setSocketConnected(false));
        }

        function autoInteractionUpdateHandler({ data }: { data: AutoInteractionMessage }) {
            // if message is activity message
            if (data.message_type === "ai" && isBrainActivityMessage(data)) {
                dispatch(addBrainActivityMessage(data));
                dispatch(setIsCollaborateLoading(true));

                if (data.message || data.file) {
                    dispatch(addAutoInteractionMessage(data));
                }
            } else if (data.message_type === "ai" && (data.message || data.file)) {
                dispatch(addAutoInteractionMessage(data));
            } else {
                dispatch(addAutoInteractionMessage(data));
            }
            /**
             * Show loading animation when user sends a message
             */
            if (data.message_type === "user") {
                dispatch(setIsCollaborateLoading(true));
            } else {
                dispatch(setIsCollaborateLoading(false));
            }
            /**
             * Hide loading animation when ai stops executing
             */
            hideLoaderAfterExecution(data);
        }

        /**
         * When the socket connects join the channel
         */

        socket.on<"connect">("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("connect_error", console.log);

        if (collaborateState.socketRoom) {
            socket.on(collaborateState.socketRoom, ({ data }: { data: AutoInteractionMessage }) => {
                // if message is an activity message
                if (data.message_type === "ai" && isBrainActivityMessage(data)) {
                    dispatch(addBrainActivityMessage(data));
                    dispatch(setIsCollaborateLoading(true));

                    if (data.message) {
                        dispatch(addAutoInteractionMessage(data));
                    }
                } else if (data.message_type === "ai" && data.message) {
                    dispatch(addAutoInteractionMessage(data));
                } else {
                    dispatch(addAutoInteractionMessage(data));
                }
                /**
                 * Show loading animation when user sends a message
                 */
                if (data.message_type === "user") {
                    dispatch(addAutoInteractionMessage(data));
                    dispatch(setIsCollaborateLoading(true));
                } else {
                    // console.log("App.tsx:243")
                    dispatch(setIsCollaborateLoading(false));
                }

                hideLoaderAfterExecution(data);
            });
        }

        /**
         *
         * Connect to specific interaction room
         */
        const autoInteractionId = collaborateState.autoInteractionId === "object" ? collaborateState.selectedCollaborationApp?.id : collaborateState.autoInteractionId;

        if (autoInteractionId) {
            console.log(`listening to auto-interaction-update-${autoInteractionId}`); //debug

            socket.on(`auto-interaction-update-${autoInteractionId}`, autoInteractionUpdateHandler);
        }

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
            socket.on(`auto-interaction-update-${autoInteractionId}`, autoInteractionUpdateHandler);
        };
    }, [collaborateState.isInitialized, collaborateState.socketRoom, collaborateState.autoInteractionId]);

    useEffect(() => {
        if (collaborateState.selectedCollaborationApp?.id) {
            const socketHandler = ({ data }: { data: AutoInteractionMessage }) => {
                // console.log(data);
                // if message is activity message
                if (data.message_type === "ai" && (data.thought || data.action || data.observation || data.content_log || data.message || data.evaluation || data.tool_result)) {
                    dispatch(addBrainActivityMessage(data));
                    dispatch(setIsCollaborateLoading(true));

                    if (data.message) {
                        dispatch(addAutoInteractionMessage(data));
                    }
                } else if (data.message_type === "user") {
                    /**
                     * Show loading animation when user sends a message
                     */
                    dispatch(addAutoInteractionMessage(data));
                    dispatch(setIsCollaborateLoading(true));
                } else {
                    dispatch(addAutoInteractionMessage(data));
                }

                /**
                 * Hide loading animation when ai stops executing
                 */
                hideLoaderAfterExecution(data);
            };

            socket.on(`collaboration-app-${collaborateState.selectedCollaborationApp?.id}`, socketHandler);

            return () => {
                socket.off(`collaboration-app-${collaborateState.selectedCollaborationApp?.id}`, socketHandler);
            };
        }
    }, [collaborateState.selectedCollaborationApp]);

    if (!authenticated) {
        return <></>;
    }

    return <>{children}</>;
};

export default KeycloakWrapper;
