import React, { useEffect, useState } from "react";
import { Box, Table, Thead, Th, Tr, Tbody, Td, Tag, IconButton, HStack } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy, FaCheck } from "react-icons/fa";
import { useHttpClient } from "../utils/http.utils";

type PerceptorItem = {
    status: string;
    user: {
        id: string;
    };
    user_perceptor: {
        perceptor_code: string;
    };
};

type PerceptorTableProps = {
    lists: PerceptorItem[];
    id: string;
};

export const PerceptorTable: React.FC<PerceptorTableProps> = ({ lists, id }) => {
    const [gmailUrl, setGmailUrl] = useState<string>("");
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [twilioUrl, setTwilioUrl] = useState<string>("");
    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const { listGmailUrl } = useHttpClient();

    useEffect(() => {
        const fetchGmailUrl = async () => {
            const data = await listGmailUrl();

            setGmailUrl(data?.url);
        };

        fetchGmailUrl();

        const url = `${process.env.REACT_APP_API_URL}/api/v1/webhook/twilio/inbound-sms/${id}`;
        setTwilioUrl(url);
    }, [id]);

    return (
        <Box width="100%" height="100%">
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Status</Th>
                        <Th>Activation URL</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {lists.map((item, index) => (
                        <Tr key={index}>
                            <Td>{capitalizeFirstLetter(item.user_perceptor.perceptor_code)}</Td>
                            <Td>
                                <Tag colorScheme={item.status === "active" ? "green" : "yellow"} variant="solid" color="white">
                                    {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                                </Tag>
                            </Td>
                            <Td>
                                {item.status === "active" ? (
                                    item.user_perceptor.perceptor_code !== "twilio" ? (
                                        "..."
                                    ) : (
                                        <HStack>
                                            <input type="text" readOnly value={twilioUrl || ""} className="h-[40px] bg-gray-100 px-2 outline-none rounded-[8px] border w-[90%]" />
                                            <CopyToClipboard
                                                text={twilioUrl || ""}
                                                onCopy={() => {
                                                    setIsCopied(true);
                                                    setTimeout(() => {
                                                        setIsCopied(false);
                                                    }, 1000);
                                                }}
                                            >
                                                <IconButton icon={isCopied ? <FaCheck /> : <FaCopy />} h={"40px"} w={"40px"} aria-label="Copy" />
                                            </CopyToClipboard>
                                        </HStack>
                                    )
                                ) : (
                                    <HStack>
                                        <input type="text" readOnly value={gmailUrl || ""} className="h-[40px] bg-gray-100 px-2 outline-none rounded-[8px] border w-[90%]" />
                                        <CopyToClipboard
                                            text={gmailUrl || ""}
                                            onCopy={() => {
                                                setIsCopied(true);
                                                setTimeout(() => {
                                                    setIsCopied(false);
                                                }, 1000);
                                            }}
                                        >
                                            <IconButton icon={isCopied ? <FaCheck /> : <FaCopy />} h={"40px"} w={"40px"} aria-label="Copy" />
                                        </CopyToClipboard>
                                    </HStack>
                                )}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default PerceptorTable;
